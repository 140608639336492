export {default as Dashboard} from './Dashboard/Dashboard'

export {default as Login} from './Auth/Login';

export {default as Profile} from './profile/Profile';

export {default as NotFound} from './PageNotFound';

export {default as Xizmatlar} from './Xizmatlar/Xizmatlar';


export {default as Contracts} from './contracts/Contracts'
export {default as ContractDetail} from './contracts/ContractDetail'

export {default as Applications} from './Applications/Applications'
export {default as ApplicationDetail} from './Applications/ApplicationDetail'

export {default as CreateColocation} from './CreateContracts/Colocation/CreateColocation'
export {default as CreateVps} from './CreateContracts/Vps/CreateVps'
export {default as CreateEmail} from './CreateContracts/Email/CreateEmail'
export {default as CreateExpertise} from './CreateContracts/Expertise/CreateExpertise'
export {default as CreateCertification} from './CreateContracts/Certification/CreateCertification'

export {default as DataCenter} from './DataCenter/DataCenter'
export {default as AdmissionDataCenter} from './DataCenter/AdmissionDataCenter'

export {default as Registry} from './Registry/Registry'
export {default as RegistryDetail} from './Registry/RegistryDetail'
export {default as CreateRegistry} from './Registry/CreateRegistry'
