import React from 'react'
import {useStateContext} from "../../contexts/ContextProvider";

const EmptyIcon = () => {

  const {currentColor} = useStateContext();

  return (
    <svg
      width="274"
      height="254"
      viewBox="0 0 274 254"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.43">
        <path
          d="M143.301 1.5052L16.248 36.7401C11.9416 37.9396 8.28683 40.7984 6.08553 44.6892C3.88423 48.5799 3.31618 53.185 4.50599 57.4942L45.8791 206.681C47.0786 210.987 49.9373 214.642 53.8281 216.843C57.7189 219.045 62.324 219.613 66.6331 218.423L193.686 183.188C197.993 181.988 201.647 179.13 203.849 175.239C206.05 171.348 206.618 166.743 205.428 162.434L164.055 13.2473C162.856 8.94086 159.997 5.28609 156.106 3.08479C152.215 0.883473 147.61 0.3154 143.301 1.5052Z"
          fill={currentColor}
        />
        <path
          d="M144.597 6.18317L17.5442 41.4181C14.4775 42.2723 11.8749 44.3081 10.3073 47.0788C8.73968 49.8495 8.33515 53.1289 9.18244 56.1975L50.5555 205.384C51.4097 208.451 53.4455 211.053 56.2162 212.621C58.9869 214.189 62.2664 214.593 65.335 213.746L192.388 178.511C195.455 177.657 198.057 175.621 199.625 172.85C201.193 170.08 201.597 166.8 200.75 163.732L159.377 14.545C158.523 11.4783 156.487 8.87567 153.716 7.30807C150.945 5.74046 147.666 5.33591 144.597 6.18317Z"
          fill="white"
        />
        <rect
          x="42.7637"
          y="111.848"
          width="126"
          height="26"
          rx="8"
          transform="rotate(-15.5 42.7637 111.848)"
          fill="#F2F2F2"
        />
        <rect
          x="49.1855"
          y="116.293"
          width="116"
          height="14"
          rx="4"
          transform="rotate(-15.5 49.1855 116.293)"
          fill="#DEDEDE"
        />
        <rect
          x="31.0049"
          y="69.4473"
          width="126"
          height="26"
          rx="8"
          transform="rotate(-15.5 31.0049 69.4473)"
          fill={currentColor}
        />
        <rect
          x="37.4268"
          y="73.8926"
          width="116"
          height="14"
          rx="4"
          transform="rotate(-15.5 37.4268 73.8926)"
          fill="#DEDEDE"
        />
        <rect
          x="52.6514"
          y="147.502"
          width="126"
          height="26"
          rx="8"
          transform="rotate(-15.5 52.6514 147.502)"
          fill="#F2F2F2"
        />
        <rect
          x="59.0732"
          y="151.947"
          width="116"
          height="14"
          rx="4"
          transform="rotate(-15.5 59.0732 151.947)"
          fill="#DEDEDE"
        />
      </g>
      <path
        d="M229.369 46.5391H97.5204C93.0501 46.5441 88.7643 48.3222 85.6033 51.4832C82.4423 54.6442 80.6642 58.93 80.6592 63.4004V218.218C80.6642 222.688 82.4423 226.974 85.6033 230.135C88.7643 233.296 93.0501 235.074 97.5204 235.079H229.369C233.839 235.074 238.125 233.296 241.286 230.135C244.447 226.974 246.225 222.688 246.23 218.218V63.4004C246.225 58.93 244.447 54.6442 241.286 51.4832C238.125 48.3222 233.839 46.5441 229.369 46.5391Z"
        fill={currentColor}
      />
      <path
        d="M229.368 51.3926H97.52C94.3366 51.3961 91.2845 52.6623 89.0335 54.9134C86.7825 57.1644 85.5163 60.2164 85.5127 63.3999V218.217C85.5163 221.401 86.7825 224.453 89.0335 226.704C91.2845 228.955 94.3366 230.221 97.52 230.224H229.368C232.552 230.221 235.604 228.955 237.855 226.704C240.106 224.453 241.372 221.401 241.376 218.217V63.3999C241.372 60.2165 240.106 57.1645 237.855 54.9134C235.604 52.6624 232.552 51.3962 229.368 51.3926Z"
        fill="white"
      />
      <rect x="103" y="126" width="126" height="26" rx="8" fill="#F2F2F2" />
      <rect x="108" y="132" width="116" height="14" rx="4" fill="#DEDEDE" />
      <rect x="103" y="82" width="126" height="26" rx="8" fill={currentColor} />
      <rect x="108" y="88" width="116" height="14" rx="4" fill="white" />
      <rect x="103" y="163" width="126" height="26" rx="8" fill="#F2F2F2" />
      <rect x="108" y="169" width="116" height="14" rx="4" fill="#DEDEDE" />
      <path
        d="M243.22 253.915C260.219 253.915 274 240.134 274 223.135C274 206.136 260.219 192.355 243.22 192.355C226.221 192.355 212.44 206.136 212.44 223.135C212.44 240.134 226.221 253.915 243.22 253.915Z"
        fill={currentColor}
      />
      <path
        d="M257.941 219.119H247.235V208.413C247.235 207.348 246.813 206.327 246.06 205.574C245.307 204.821 244.286 204.398 243.221 204.398C242.156 204.398 241.135 204.821 240.382 205.574C239.629 206.327 239.206 207.348 239.206 208.413V219.119H228.5C227.435 219.119 226.414 219.542 225.661 220.295C224.908 221.048 224.485 222.069 224.485 223.134C224.485 224.199 224.908 225.22 225.661 225.973C226.414 226.726 227.435 227.149 228.5 227.149H239.206V237.854C239.206 238.919 239.629 239.94 240.382 240.693C241.135 241.446 242.156 241.869 243.221 241.869C244.286 241.869 245.307 241.446 246.06 240.693C246.813 239.94 247.235 238.919 247.235 237.854V227.149H257.941C259.006 227.149 260.027 226.726 260.78 225.973C261.533 225.22 261.956 224.199 261.956 223.134C261.956 222.069 261.533 221.048 260.78 220.295C260.027 219.542 259.006 219.119 257.941 219.119Z"
        fill="white"
      />
      {/*#343a40*/}
    </svg>
  )
}

export default EmptyIcon
