import React from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
];

export const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    to: '/profile'
  },
];

export const colocationStatus = [
  { title: "Yangi", value: 1 },
  { title: "To'lov kutilmoqda", value: 2 },
  { title: "Aktiv", value: 3 },
  { title: "Rad etilgan", value: 4 },
  { title: "Bekor qilingan", value: 5 },
  { title: "Yakunlangan", value: 6 },
  { title: "Qo'shimcha shartnoma mavjud", value: 7 },
  { title: "Shartnomani to'xtatish kutilmoqda", value: 8 },
  { title: "Shartnomani raqami bron qilingan", value: 9 },
  { title: "Mijoz imzolashi kutilmoqda", value: 10 },
]

export const e_xat = [
  { title: "Yangi", value: 1 },
  { title: "To'lov kutilmoqda", value: 2 },
  { title: "Aktiv", value: 3 },
  { title: "Rad etilgan", value: 4 },
  { title: "Bekor qilingan", value: 5 },
  { title: "Yakunlangan", value: 6 },
  { title: "Qo'shimcha shartnoma mavjud", value: 7 },
  { title: "Shartnomani to'xtatish kutilmoqda", value: 8 },
  { title: "Shartnomani raqami bron qilingan", value: 9 },
]

export const tte_certification = [
  { title: "Yangi", value: 1 },
  { title: "To'lov kutilmoqda", value: 2 },
  { title: "Aktiv", value: 3 },
  { title: "Rad etilgan", value: 4 },
  { title: "Bekor qilingan", value: 5 },
  { title: "Yakunlangan", value: 6 },
  { title: "Mijoz imzolashi kutilmoqda", value: 7 },
  { title: "Shartnomani raqami bron qilingan", value: 9 },
]

export const expertiseStatus = [
  { title: "Yangi", value: 1 },
  { title: "Mijoz imzolashi kutilmoqda", value: 2 },
  { title: "To'lov kutilmoqda", value: 3 },
  { title: "Aktiv", value: 4 },
  { title: "Rad etilgan", value: 5 },
  { title: "Bekor qilingan", value: 6 },
  { title: "Yakunlangan", value: 7 },
  { title: "Shartnomani raqami bron qilingan", value: 8 },
]


export const vpsStatus = [
  { title: "Yangi", value: 1 },
  { title: "To'lov kutilmoqda", value: 2 },
  { title: "Aktiv", value: 3 },
  { title: "Rad etilgan", value: 4 },
  { title: "Bekor qilingan", value: 5 },
  { title: "Yakunlangan", value: 6 },
  { title: "Qo'shimcha shartnoma mavjud", value: 7 },
  { title: "Shartnomani to'xtatish kutilmoqda", value: 8 },
  { title: "Mijoz imzolashi kutilmoqda", value: 10 }
]
